import React from "react"

const Title = ({ value }) => {
  const name = "Milk Soup"

  return (
    <h1
      style={{
        width: '100vw',
        height: '100vh',
        fontSize: '6rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0
      }}>{name + ' ' + value}</h1>
  )
}

export default Title