import Title from "../components/Title";

const Home = ({ bg, number }) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: bg,
        margin: 0
      }}>
      <Title value={number} />
    </div>
  );
}

export default Home;