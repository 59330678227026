import React from 'react'
import Home from './pages/Home'
import useWebSocket from 'react-use-websocket'


const App = () => {
  const [color, setColor] = React.useState(null)
  const [num, setNum] = React.useState(0)
  const { sendMessage, lastMessage, readyState } = useWebSocket('wss://vuglerod2.virtualized.dev/ws')

  const lastMessageHandler = () => {
    if (!lastMessage) return

    if (lastMessage.data.startsWith('color:')) {
      setColor(lastMessage.data.substring(6))
    }
    else if (lastMessage.data.startsWith('num:')) {
      setNum(parseInt(lastMessage.data.substring(4)))
    }
  }

  React.useEffect(() => sendMessage('color'), [sendMessage, readyState]);

  React.useEffect(() => sendMessage('num'), [sendMessage, readyState]);

  React.useEffect(lastMessageHandler, [lastMessage])

  return (
    <Home bg={color} number={num} />
  )
}

export default App